import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageMetaService {

  defaultEnMetaTags = {
    keywords: 'battery, miami, broken, replace, back glass, charging port , water, damage, galaxy, charging, glass, camera, apple, motorola, repair,fixing, price, water, flagler, lite, broken,busted,iphone,samsung,nokia, lg, xiaomi , x, xr, 11 , 12, pro, plus ,apple,smartphone,help,  laptop, tablet,  phone mobile, stores, orders, order, start my order, phone,miami, map , location, repairs, appointment, repairs service, fix service, appointment repairs service, repairs devices, repairs near me, onsite mobile Screen replacement at your Doorstep, Miami mobile phone repair,Cell phone repair Miami,iPhone repair Miami,Samsung phone repair Miami,iPad repair Miami,Android phone repair Miami,Miami phone screen repair,Miami mobile device repair,Miami cracked screen repair,Tablet repair Miami,Mobile phone technician Miami,Miami phone battery replacement,Miami water damage repair,Miami smartphone repair service,Mobile repair shop in Miami,Miami phone fix,Miami phone screen replacement,Affordable mobile repair Miami,Miami cell phone diagnostic,Miami phone repair near me',
    description: "Discover Miami's trusted repair specialists for iPhone, Samsung, Xiaomi. Request your free iPhone repairs quote today!"
  };

  defaultEsMetaTags = {
    keywords: 'Iphone, Miami, fix, repair, battery, broken, replace, backglass, charging port , water, damage, galaxy, charging, glass, , camera, apple, motorola, repair,fixing, price, water, flagler, lite, broken,busted,nokia, lg, xiaomi , x, xr, 11 , 12, pro, plus ,apple,smartphone,help,dispositivo, teléfono móvil, tableta, reparaciones, computadora, computadora portátil, laptop, tablet,  phone mobile, servicios de reparaciones, pedidos, tiendas de electrónicos, stores, orders, order, start my order, phone,miami, map , location, repairs, appointment, repairs service, fix service, appointment repairs service, repairs devices, repairs near me, onsite mobile Screen replacement at your Doorstep, reparación de celulares a domicilio, repairshop, taller de reparación de celulares, fix device near me',
    description: '¿Tiene algún problema con su dispositivo? Arreglamos y reparamos la mayoría de los modelos de teléfonos inteligentes. Haga una reserva hoy mismo en Procell Stores u obtenga un técnico cerca de usted.'
  };

  setTag(metaName: string, metaContent: string): void {
    this.meta.updateTag({ name: `${metaName}`, content: `${metaContent}`}, `name="${metaName}"`);
  }

  setDedautlTagDescription(): void {
    if (this.locale === 'es-US') {
      this.setTag('description', this.defaultEsMetaTags.description);
    } else {
      this.setTag('description', this.defaultEnMetaTags.description);
    }
  }

  setDefaultKeywords(): void {
    if (this.locale === 'es-US') {
      this.setTag('keywords', this.defaultEsMetaTags.keywords);
    } else {
      this.setTag('keywords', this.defaultEnMetaTags.keywords);
    }
  }

  setDefaultTags(): void {
    this.setDedautlTagDescription();
    this.setDefaultKeywords();
  }

  constructor(@Inject(LOCALE_ID) public locale: string, private meta: Meta) {
  }
}
