import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {PageTitleService} from "../services/page-title.service";
import {PageMetaService} from "../services/page-meta.service";
import {isPlatformBrowser, LocationStrategy} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    template: '',
    standalone: false
})
export class PlatformBrowserComponent implements OnInit {

  @ViewChild('pageTitle', { static: true }) private pageTitle: ElementRef|undefined;
  @ViewChild('pageMetaDescription', { static: true }) private pageMetaDescription: ElementRef|undefined;
  @ViewChild('pageMetaKeywords', { static: true }) private pageMetaKeywords: ElementRef|undefined;
  isPopState = false;
  constructor(
    protected pageTitleService: PageTitleService,
    protected pageMeta: PageMetaService,
    @Inject(PLATFORM_ID) protected  platformId: object|any,
    protected router: Router,
    protected locStrat: LocationStrategy,
    ) {
    this.setTitle();
    this.setMetas('');
    this.locStrat.onPopState(() => {
      this.isPopState = true;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && !this.isPopState) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
        this.isPopState = false;
      }
      if (event instanceof NavigationEnd) {
        this.isPopState = false;
      }
    });

  }

  ngOnInit(): void {
    this.setTitle();
    this.setMetas();

  }

  protected setTitle(title?: string|undefined|null): void {
    if (title) {
      this.pageTitleService.title = `${title ?? ''}` ;
    }

  }

  setMetas(metas? : string |undefined |null , description?: string  | undefined | null): void {
    if (!this.pageMetaDescription?.nativeElement
      && !this.pageMetaKeywords?.nativeElement
      && !metas
      && !description
    ) {
      this.pageMeta.setDefaultTags();
    }

    if (!this.pageMetaDescription?.nativeElement && !description) {
      this.pageMeta.setDedautlTagDescription();
    }

    if (!this.pageMetaKeywords?.nativeElement && !metas) {
      this.pageMeta.setDefaultKeywords();
    }
    if (description) {
      this.pageMeta.setTag('description', description);
    }else if (this.pageMetaDescription?.nativeElement) {
      this.pageMeta.setTag('description', this.pageMetaDescription?.nativeElement.innerText);
    }

    if (metas) {
      this.pageMeta.setTag('keywords', metas);
    } else if (this.pageMetaKeywords?.nativeElement) {
      this.pageMeta.setTag('keywords', this.pageMetaKeywords?.nativeElement.innerText);
    }
  }

  setNoIndexMeta() {
    this.pageMeta.setTag('robots','noindex, nofollow');
  }
  setIndexMeta() {
    this.pageMeta.setTag('robots','index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1');
  }

}
