import {Inject, Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  _title: string|undefined|null = '';
  _originalTitle = 'Procell Phone Repair Miami.';

  get title(): string|undefined|null { return this._title;  }

  set title(title: string|undefined|null) {
    this._title = title;
    if (title !== '') {
      title = `${title} `;
    } else {
      title = this._originalTitle;
    }
    this.bodyTitle.setTitle(title);
  }
  createLinkForCanonicalURL() {
    const els  : any = [];
    [ 'canonical-el' ].forEach(c => {
      els.push(...Array.from(this.doc.head.getElementsByClassName(c)));
    });
    els.forEach((el : any) => this.doc.head.removeChild(el));
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('class', 'canonical-el');
    link.setAttribute('href', this.doc.URL.replace('http://','https://'));
    this.doc.head.appendChild(link);
    //console.log(this.doc.URL);

  }


  injectBreadCumbs(brand : string|null, model : string|null = null, issue: string|null = null) {
    let data = {
      "@context": "http://www.schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{}],
    };
    data.itemListElement.pop();

    let data0 =  {
      "@type": "ListItem",
      "position": 1,
      "name": 'Home',
      "item": `https://procellrepair.com`
    };
    data.itemListElement.push(data0);
    let data1 =  {
      "@type": "ListItem",
      "position": 2,
      "name": `Repairs ${brand}`,
      "item": `https://procellrepair.com/repair/${brand}`
    };

    data.itemListElement.push(data1);
    if (model) {
      let data2 = {
        "@type": "ListItem",
        "position": 3,
        "name": `Repair List for ${model} `,
        "item": `https://procellrepair.com/repair/${brand}/${model}`
      };
      data.itemListElement.push(data2);
    }
      if(issue) {
        let data3 = {
          "@type": "ListItem",
          "position": 4,
          "name": issue?.replace('-', ' ').toUpperCase() + ` ${model}`,
          "item": `https://procellrepair.com/repair/${brand}/${model}/${issue}`
        };
        data.itemListElement.push(data3);
      }
    let scriptT: HTMLScriptElement = this.doc.createElement('script');
    scriptT.setAttribute('type', 'application/ld+json');
    scriptT.setAttribute('id', 'bread-auto');
    scriptT.innerHTML = JSON.stringify(data);
    this.doc.head.appendChild(scriptT);
  }
  injectBreadCumbsSpecs(brand : string|null|undefined = null, model : string|null = null, modelId: string|null = null) {
    let data = {
      "@context": "http://www.schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{}],
    };
    data.itemListElement.pop();

    let data0 =  {
      "@type": "ListItem",
      "position": 1,
      "name": 'Home, Procell Miami Phone Repair',
      "item": `https://procellrepair.com`
    };
    data.itemListElement.push(data0);
    let data1 =  {
      "@type": "ListItem",
      "position": 2,
      "name": 'Device Specifications, Procell Miami Phone Repair',
      "item": `https://procellrepair.com/device-specs`
    };
    data.itemListElement.push(data1);
    if (brand) {
      let data2 = {
        "@type": "ListItem",
        "position": 3,
        "name": `${brand} `,
        "item": `https://procellrepair.com/device-specs/${brand}`
      };
      data.itemListElement.push(data2);
    }
      if(modelId) {
        let data3 = {
          "@type": "ListItem",
          "position": 4,
          "name": model?.replace('-', ' ').toUpperCase(),
          "item": `https://procellrepair.com/device-specs/device/${brand}/${model}/${modelId}`
        };
        data.itemListElement.push(data3);
      }
    let scriptT: HTMLScriptElement = this.doc.createElement('script');
    scriptT.setAttribute('type', 'application/ld+json');
    scriptT.setAttribute('id', 'bread-auto');
    scriptT.innerHTML = JSON.stringify(data);
    this.doc.head.appendChild(scriptT);
  }


  injectBreadCumbsGeneric(firstPath : string|null|undefined = null, secodnPath : string|null = null) {
    let data = {
      "@context": "http://www.schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{}],
    };
    data.itemListElement.pop();

    let data0 =  {
      "@type": "ListItem",
      "position": 1,
      "name": 'Home, Procell Miami Phone Repair',
      "item": `https://procellrepair.com`
    };
    data.itemListElement.push(data0);

    if (firstPath) {
      let data2 = {
        "@type": "ListItem",
        "position": 2,
        "name": `${firstPath} `,
        "item": `https://procellrepair.com/${firstPath}`
      };
      data.itemListElement.push(data2);
    }
      if(secodnPath) {
        let data3 = {
          "@type": "ListItem",
          "position": 3,
          "name": `${firstPath} ${secodnPath} `,
          "item": `https://procellrepair.com/${firstPath}/${secodnPath}`
        };
        data.itemListElement.push(data3);
      }
    let scriptT: HTMLScriptElement = this.doc.createElement('script');
    scriptT.setAttribute('type', 'application/ld+json');
    scriptT.setAttribute('id', 'bread-auto-generic');
    scriptT.innerHTML = JSON.stringify(data);
    this.doc.head.appendChild(scriptT);
  }


  injectFAQPageSchema() {
    const els  : any = [];
    [ 'faq-page-scheme-cl' ].forEach(c => {
      els.push(...Array.from(this.doc.head.getElementsByClassName(c)));
    });
    els.forEach((el : any) => this.doc.head.removeChild(el));

    let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "Is there a repair facility or only repairs available at home?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We repair most electrical devices at our Procell locations, where you can walk in and can get them repaired instantly.You can find us in  Flagler 1279 W Flagler St, Miami, FL 33135, USA. or  Quailroost 11600 Quail Roost Drive Miami Florida 33157, or Allapattah 3540 NW 17 AVE MIAMI FL 33135, USA"
            }
          },
          {
            "@type": "Question",
            "name": "What kind of parts does Procell Repair use?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Best parts available from distributors.  ▪  Procell Repair has a strict policy of using exclusively use the best parts available for all its repairs. As a customer, you don’t have to worry. ▪ To ensure quality and reliability, we test all our parts in-house before using them in a repair. Procell Repair will also throw in 1 month of free replacement warranty (subject to certain conditions [see warranty policy]), on-screen repairs & replacement warranty for other spare parts repaired by Procell repair.  ▪  In addition to this, you will indeed be saving money with Procell Repair’s prices. All Procell Repair technicians are experts, so you don’t have to worry about faulty installations. ▪ We do not necessarily use original Apple parts to repair the phone. ▪ Please note that repairing your phone on Procell Repair will void the Apple warranty."
            }
          },
          {
            "@type": "Question",
            "name": "How Can I Schedule An Appointment?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Once you’ve completed registration and signed up on the Procell Repair website: ▪  You will be asked to choose your smartphone brand (all major models are supported), model, colour variant, and issue. ▪  After filling in these details, you will be taken to a new tab called “Appointment”. Here, you can select the day and the time slot available for make the repair.  ▪  Sometimes, dates and time slots may not be available due to excessive demand. ▪  After selecting your preferred date and time, just type in the address you want us to visit. ▪  After finish payment process you will then be greeted with your appointment confirmation. ▪  In some cases, you may not be able to get your desired time slot as we may have our hands full with repairs. Please select the next most convenient time for your repair in such a case."
            }
          }
        ]
    };

    let scriptT: HTMLScriptElement = this.doc.createElement('script');
    scriptT.setAttribute('type', 'application/ld+json');
    scriptT.setAttribute('id', 'faq-page-scheme');
    scriptT.setAttribute('class', 'faq-page-scheme-cl');
    scriptT.innerHTML = JSON.stringify(data);
    this.doc.head.appendChild(scriptT);
  }
  injectFAQPageSchemaMain() {
    const els  : any = [];
    [ 'faq-page-scheme-main' ].forEach(c => {
      els.push(...Array.from(this.doc.head.getElementsByClassName(c)));
    });
    els.forEach((el : any) => this.doc.head.removeChild(el));

    let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "How can I identify the front camera issue  in my phone?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": " 1 - I can't hear anything when I make a call, 2 - Seeing a black screen instead of the phone camera viewfinder. 3 - The phone camera freezing when switching between the two cameras. "
            }
          },
          {
            "@type": "Question",
            "name": "How can I identify the home button issue  in my phone?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "1 - If that doesn't work, you know your Home button definitely has a hardware problem. 2 - It said home but needs service. "
            }
          },
          {
            "@type": "Question",
            "name": "How can I identify the proximity sensor issue in my phone?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "1 - Dust or a Crack on the Screen Sensor. 2 - When I have a call and I hold the phone in my ear the touchscreen is enabled."
            }
          },
          {
            "@type": "Question",
            "name": "How can I identify when my phone battery needs replacement?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": " 1 - Device will not turn on. This is definitely the most obvious and easiest way to determine if your battery has simply had enough. 2 - Only showing signs of life when connected to a charger or dying fast even after fully charged. 3 - Overheating or battery bulging. "
            }
          },
          {
            "@type": "Question",
            "name": "How can I identify if my phone have water damage?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": " 1 - Drop liquid, such as water or other fluids over your phone. 2 - Drop the phone into a bucket with water. 3 - Accidentally drop the phone into the toilet ."
            }
          },
          {
            "@type": "Question",
            "name": "How can I identify if my phone lcd  needs a replacement?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "1 - A pixelated screen can indicate LCD damage.This would look like a patch of multicolored dots, a line or lines of discoloration, or a screen with rainbow colors. For many people, these colors are an easy way to know that their LCD is broken."
            }
          },
          {
            "@type": "Question",
            "name": "How can I identify if my phone screen needs to be replaced?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": " 1 - Whether the glass is cracked or not, examine the display and look for: - Black spots, discolored areas, or blurred sections on the screen, 2 - My phone screen that stays completely black ,3 - Lines or patterns that aren't simple cracks in the glass "
            }
          }
        ]
    };

    let scriptT: HTMLScriptElement = this.doc.createElement('script');
    scriptT.setAttribute('type', 'application/ld+json');
    scriptT.setAttribute('id', 'faq-page-scheme-m');
    scriptT.setAttribute('class', 'faq-page-scheme-main');
    scriptT.innerHTML = JSON.stringify(data);
    this.doc.head.appendChild(scriptT);
  }

  constructor(private bodyTitle: Title,  @Inject(DOCUMENT) private doc: Document) {}
}
